import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { buttonGroupClasses } from '@mui/material/ButtonGroup';
import { chipClasses } from '@mui/material/Chip';
import { sliderClasses } from '@mui/material';
import { listItemClasses } from '@mui/material';
import { switchClasses } from '@mui/material/Switch';
import { autocompleteClasses, inputBaseClasses, inputAdornmentClasses, selectClasses, buttonClasses } from '@mui/material';
import { alertClasses, iconClasses } from '@mui/material';
import colorPalette from '../setup/color-palette.scss';

declare module '@mui/material/Alert' {
    interface AlertPropsColorOverrides {
        secondary: true;
    }
}

declare module '@mui/material/styles' {
    interface PaletteColor {
        background: string;
    }

    interface SimplePaletteColorOptions {
        background?: string;
    }
}

declare module '@mui/material/styles' {
    interface PaletteOptions {
        avatar?: {
            backgrounds: string[];
        };
        Chip?: {
            primaryOutlinedColor: string;
            secondaryOutlinedColor: string;
            infoOutlinedColor: string;
            successOutlinedColor: string;
            warningOutlinedColor: string;
            errorOutlinedColor: string;
        };
    }
    interface Palette {
        avatar?: {
            backgrounds: string[];
        };
        Chip?: {
            primaryOutlinedColor: string;
            secondaryOutlinedColor: string;
            infoOutlinedColor: string;
            successOutlinedColor: string;
            warningOutlinedColor: string;
            errorOutlinedColor: string;
        };
    }
}

//Returns the Cape theme
const capeTheme = () => {
    return {
        cssVarPrefix: '',
        colorSchemes: {
            light: {
                palette: {
                    primary: {
                        main: colorPalette.blue500,
                        dark: colorPalette.blue600,
                        light: colorPalette.blue400,
                        contrastText: colorPalette.white
                    },
                    secondary: {
                        main: colorPalette.purple500,
                        dark: colorPalette.purple700,
                        light: colorPalette.purple300,
                        contrastText: colorPalette.white
                    },
                    error: {
                        main: colorPalette.red700,
                        dark: colorPalette.red800,
                        light: colorPalette.red400,
                        contrastText: colorPalette.white
                    },
                    warning: {
                        main: colorPalette.orange600,
                        dark: colorPalette.orange900,
                        light: colorPalette.orange500,
                        contrastText: colorPalette.white
                    },
                    info: {
                        main: colorPalette.lightBlue700,
                        dark: colorPalette.lightBlue900,
                        light: colorPalette.lightBlue500,
                        contrastText: colorPalette.white
                    },
                    success: {
                        main: colorPalette.green600,
                        dark: colorPalette.green900,
                        light: colorPalette.green300,
                        contrastText: colorPalette.white
                    },
                    grey: {
                        50: colorPalette.grey50,
                        100: colorPalette.grey100,
                        200: colorPalette.grey200,
                        300: colorPalette.grey300,
                        400: colorPalette.grey400,
                        500: colorPalette.grey500,
                        600: colorPalette.grey600,
                        700: colorPalette.grey700,
                        800: colorPalette.grey800,
                        900: colorPalette.grey900
                    },
                    text: {
                        primary: colorPalette.grey700,
                        secondary: colorPalette.grey500,
                        disabled: colorPalette.grey400,
                        textAction: colorPalette.blue600,
                        invert: colorPalette.white
                    },
                    action: {
                        active: 'rgba(0, 0, 0, 0.64)'
                    },
                    background: {
                        default: '#f3f5f8',
                        primary: colorPalette.blue50,
                        secondary: colorPalette.purple50,
                        error: colorPalette.red50,
                        warning: colorPalette.orange50,
                        info: colorPalette.lightBlue50,
                        success: colorPalette.green50
                    },
                    divider: 'rgba(0, 0, 0, 0.08)',
                    FilledInput: {
                        bg: 'rgba(0, 0, 0, 0.04)',
                        disabledBorder: 'rgba(0, 0, 0, 0.23)'
                    },
                    Chip: {
                        primaryOutlinedColor: 'var(--palette-primary-dark)',
                        secondaryOutlinedColor: 'var(--palette-secondary-dark)',
                        infoOutlinedColor: 'var(--palette-info-dark)',
                        successOutlinedColor: 'var(--palette-success-dark)',
                        errorOutlinedColor: 'var(--palette-error-dark)',
                        warningOutlinedColor: 'var(--palette-waring-dark)'
                    },
                    avatar: {
                        backgrounds: [
                            colorPalette.blue400,
                            colorPalette.purple400,
                            colorPalette.deepPurple400,
                            colorPalette.amber400,
                            colorPalette.cyan400,
                            colorPalette.deepOrange400,
                            colorPalette.lightGreen400,
                            colorPalette.pink400,
                            colorPalette.teal400,
                            colorPalette.red400,
                            colorPalette.orange400
                        ]
                    }
                },
                border: {
                    default: '1px solid #E0E0E0'
                },
                shadows: [
                    'none',
                    '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2);',
                    '0 8px 8px 1px rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2)',
                    '0 8px 8px 1px rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2)',
                    '0 8px 8px 1px rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2)',
                    '0 8px 8px 1px rgba(0, 0, 0, 0.16), 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2)',
                    //Only elavation 0-5 is supported, MUI components set to anything higher will be 5 too:
                    ...Array(20).fill('0 8px 8px 1px rgba(0, 0, 0, 0.16), 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.2)')
                ]
            },
            dark: {
                palette: {
                    primary: {
                        main: colorPalette.blue500,
                        dark: colorPalette.blue600,
                        light: colorPalette.blue400,
                        contrastText: colorPalette.white
                    },
                    secondary: {
                        main: colorPalette.purple300,
                        dark: colorPalette.purple600,
                        light: colorPalette.purple200,
                        contrastText: colorPalette.white
                    },
                    error: {
                        main: colorPalette.red500,
                        dark: colorPalette.red700,
                        light: colorPalette.red300,
                        contrastText: colorPalette.white
                    },
                    warning: {
                        main: colorPalette.orange500,
                        dark: colorPalette.orange700,
                        light: colorPalette.orange300,
                        contrastText: colorPalette.white
                    },
                    info: {
                        main: colorPalette.lightBlue700,
                        dark: colorPalette.lightBlue800,
                        light: colorPalette.lightBlue300,
                        contrastText: colorPalette.white
                    },
                    success: {
                        main: colorPalette.green600,
                        dark: colorPalette.green900,
                        light: colorPalette.green300,
                        contrastText: colorPalette.white
                    },
                    background: {
                        primary: '#273246',
                        secondary: '#273246',
                        error: '#382228',
                        warning: '#362F29',
                        info: '#232B34',
                        success: '#26312F',
                        default: '#171A1E',
                        paper: '#24272D'
                    },
                    Chip: {
                        primaryOutlinedColor: 'var(--palette-primary-light)',
                        secondaryOutlinedColor: 'var(--palette-secondary-light)',
                        infoOutlinedColor: 'var(--palette-info-light)',
                        successOutlinedColor: 'var(--palette-success-light)',
                        errorOutlinedColor: 'var(--palette-error-light)',
                        warningOutlinedColor: 'var(--palette-waring-light)'
                    }
                }
            }
        },
        typography: {
            fontFamily: 'Inter',
            allVariants: {
                fontFamily: 'Inter'
            },
            h1: {
                fontSize: 28,
                fontWeight: 600,
                lineHeight: 1.6,
                letterSpacing: -0.75
            },
            h2: {
                fontSize: 20,
                fontWeight: 600,
                lineHeight: 1.2
            },
            h3: {
                fontSize: 18,
                fontWeight: 600,
                lineHeight: 1.16
            },
            h4: {
                fontSize: 16,
                fontWeight: 600,
                lineHeight: 1.5,
                letterSpacing: 0.25
            },
            h5: {
                fontSize: 14,
                fontWeight: 600,
                lineHeight: 1.3
            },
            h6: {
                fontSize: 12,
                fontWeight: 600,
                lineHeight: 1.5,
                letterSpacing: 0.15
            },
            body1: {
                fontSize: 16,
                lineHeight: 1.5,
                letterSpacing: 0.15
            },
            body2: {
                fontSize: 14,
                lineHeight: 1.4,
                letterSpacing: 0.17
            },
            caption: {
                fontSize: 12,
                fontWeight: 400,
                lineHeight: 1.5
            },
            subtitle1: {
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.75,
                letterSpacing: 0.15
            },
            subtitle2: {
                fontSize: 14,
                fontWeight: 500,
                lineHeight: 1.5
            },
            overline: {
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: 1,
                lineHeight: 2.5
            }
        },
        ToggleButton: {
            containedBorderRadius: 7
        },
        shape: {
            borderRadius: 8
        }
    };
};

//Overwrite components initial styling
const getStyleOverrides = (theme) => {
    return {
        ...theme,
        components: {
            MuiSwitch: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        marginLeft: -4,
                        [`& .${switchClasses.track}`]: {
                            borderRadius: 20
                        },
                        ...(ownerState.size === 'medium' && {
                            width: 64,
                            height: 44,
                            [`& .${switchClasses.thumb}`]: {
                                width: 16,
                                height: 16
                            },
                            [`& .${switchClasses.switchBase}`]: {
                                top: 4,
                                left: 4,
                                padding: 10
                            }
                        }),
                        ...(ownerState.size === 'small' && {
                            width: 46,
                            height: 30,
                            [`& .${switchClasses.thumb}`]: {
                                width: 12,
                                height: 12
                            },
                            [`& .${switchClasses.switchBase}`]: {
                                top: 3,
                                left: 3,
                                padding: 6
                            }
                        }),
                        ...(ownerState.edge === 'start' && {
                            marginLeft: -8
                        })
                    }),
                    thumb: ({ theme }) => ({
                        backgroundColor: theme.vars.palette.background.paper
                    }),
                    track: ({ theme }) => ({
                        backgroundColor: theme.vars.palette.grey[300],
                        opacity: 1,
                        boxShadow: `0px 0px 2px 0px rgba(0, 0, 0, 0.20) inset`
                    }),
                    switchBase: {
                        [`&.Mui-checked+.${switchClasses.track}`]: {
                            opacity: 1
                        }
                    }
                }
            },
            MuiToggleButton: {
                //https://github.com/mui/material-ui/issues/33510
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        textTransform: 'none',
                        ['&.ui-v2-toggle-button__contained']: {
                            border: `1px solid ${ownerState.color === 'standard' ? theme.vars.palette.background.default : theme.vars.palette.background[ownerState.color]}`,
                            borderRadius: theme.vars.ToggleButton.containedBorderRadius,
                            backgroundColor: 'transparent',
                            [`&.MuiToggleButton-${ownerState.color}`]: {
                                color: ownerState.color === 'standard' ? theme.vars.palette.text.primary : theme.vars.palette[ownerState.color]?.main,
                                ['&:hover']: {
                                    backgroundColor:
                                        ownerState.color === 'standard'
                                            ? theme.vars.palette.action.hover
                                            : `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / ${theme.vars.palette.action.hoverOpacity})`
                                },
                                ['&.Mui-selected']: {
                                    backgroundColor:
                                        ownerState.color === 'standard' ? theme.vars.palette.text.primary : theme.vars.palette[ownerState.color]?.main,
                                    color: ownerState.color === 'standard' ? theme.vars.palette.text.invert : theme.vars.palette[ownerState.color]?.contrastText
                                },
                                ['&.Mui-disabled']: {
                                    color: theme.vars.palette.action.disabled
                                }
                            }
                        }
                    }),
                    sizeSmall: () => ({
                        padding: '5px 7px',
                        '& .MuiIcon-root': {
                            fontSize: '1rem'
                        },
                        ['&.ui-v2-toggle-button__text']: {
                            lineHeight: '16px',
                            padding: '5px 10px',
                            fontSize: '0.688rem'
                        }
                    }),
                    sizeMedium: () => ({
                        padding: '5px 7px',
                        ['&.ui-v2-toggle-button__text']: {
                            lineHeight: '20px',
                            padding: '7px 10px',
                            fontSize: '0.813rem'
                        }
                    }),
                    sizeLarge: () => ({
                        padding: '7px 11px',
                        ['&.ui-v2-toggle-button__text']: {
                            lineHeight: '24px',
                            padding: '7px 12px',
                            fontSize: '0.938rem'
                        }
                    })
                }
            },
            MuiToggleButtonGroup: {
                //https://github.com/mui/material-ui/issues/33510
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        ['&.ui-v2-toggle-button-group__contained']: {
                            borderRadius: theme.vars.shape.borderRadius,
                            [`&.ui-v2-toggle-button-group__${ownerState.color}`]: {
                                backgroundColor:
                                    ownerState.color === 'standard' ? theme.vars.palette.background.default : theme.vars.palette.background[ownerState.color]
                            }
                        }
                    })
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        ...(ownerState.color === 'default' && {
                            '&.Mui-checked': {
                                color: theme.vars.palette.text.primary
                            }
                        })
                    })
                }
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        border: `1px solid rgba(224, 224, 224, 1)`
                    }
                }
            },
            MuiAvatarGroup: {
                styleOverrides: {
                    avatar: {
                        boxSizing: 'border-box',
                        '&:hover': {
                            zIndex: 1
                        }
                    }
                }
            },
            MuiRadio: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        ...(ownerState.color === 'default' && {
                            '&.Mui-checked': {
                                color: theme.vars.palette.text.primary
                            }
                        })
                    })
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        minHeight: 56
                    },
                    icon: {
                        fontSize: 16
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    head: ({ theme }) => ({
                        textTransform: 'none',
                        color: theme.vars.palette.text.secondary
                    })
                }
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        minHeight: 56
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        textTransform: 'none',
                        ...(ownerState.variant === 'outlined' && {
                            backgroundColor: `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / 0.08)`,
                            borderColor: `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / 0.08)`,
                            '&:hover': {
                                backgroundColor: `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / 0.16)`,
                                borderColor: `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / 0.08)`
                            },
                            '&.Mui-disabled': {
                                backgroundColor: 'transparent'
                            }
                        }),
                        ...(ownerState.variant === 'text' && {
                            '&:hover': {
                                backgroundColor: `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / 0.08)`
                            }
                        }),
                        [`&.${buttonClasses.sizeLarge} > .${buttonClasses.icon}>*:nth-of-type(1)`]: {
                            fontSize: 24
                        }
                    }),
                    sizeSmall: ({ ownerState }) => ({
                        lineHeight: 2,
                        padding: ownerState.variant === 'outlined' ? '1.1px 11px' : '2px 12px',
                        fontSize: '0.75rem'
                    }),
                    sizeMedium: ({ ownerState }) => ({
                        lineHeight: 1.537,
                        padding: ownerState.variant === 'outlined' ? '7.1px 15px' : '8px 16px',
                        fontSize: '0.813rem'
                    }),
                    sizeLarge: ({ ownerState }) => ({
                        lineHeight: 1.599,
                        padding: ownerState.variant === 'outlined' ? '7.1px 15px' : '8px 16px',
                        fontSize: '0.938rem'
                    })
                }
            },
            MuiButtonGroup: {
                styleOverrides: {
                    groupedOutlined: ({ ownerState, theme }) => ({
                        '&:hover': {
                            borderRightColor: `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / 0.08)`
                        }
                    }),
                    groupedText: ({ ownerState, theme }) => ({
                        [`&.${buttonGroupClasses.middleButton}, &.${buttonGroupClasses.firstButton}`]: {
                            borderRightColor: `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / 0.08)`
                        }
                    })
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        backgroundImage: 'none',
                        ['&.MuiPaper-root.MuiAccordion-root.Mui-expanded::before']: {
                            opacity: 1,
                            display: 'block'
                        },
                        ['&.MuiPaper-root.MuiAccordion-root:first-of-type::before']: {
                            opacity: 0
                        },
                        ['&.ui-v2-accordion__small']: {
                            ['& .MuiAccordionSummary-root']: {
                                paddingLeft: 8,
                                paddingRight: 8,
                                minHeight: 39,
                                ['& .MuiAccordionSummary-content']: {
                                    margin: '7px 0'
                                },
                                ['& .MuiAccordionSummary-expandIconWrapper .MuiIcon-root']: {
                                    fontSize: 20
                                }
                            }
                        }
                    },
                    gutters: ({ theme }) => ({
                        borderRadius: theme.vars.shape.borderRadius,
                        margin: '16px 0',
                        ['&.Mui-expanded']: {
                            margin: '16px 0'
                        },
                        ['&:first-of-type']: {
                            marginTop: 0
                        },
                        ['&.Mui-expanded:first-of-type']: {
                            marginTop: 0
                        },
                        ['&::before']: {
                            display: 'none'
                        },
                        ['&.MuiPaper-root.MuiAccordion-root.Mui-expanded::before']: {
                            display: 'none'
                        },
                        ['&.MuiPaper-root.MuiAccordion-root:first-of-type::before']: {
                            display: 'none'
                        }
                    })
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        transition: 'none',
                        ['&.Mui-expanded']: {
                            minHeight: 48,
                            margin: 0
                        }
                    },
                    content: {
                        transition: 'none',
                        ['&.Mui-expanded']: {
                            margin: '12px 0'
                        }
                    }
                }
            },
            MuiSlider: {
                styleOverrides: {
                    root: ({ theme, ownerState }) => ({
                        [`& .${sliderClasses.thumb}`]: {
                            color: 'white',
                            border: `1px solid ${theme.vars.palette.divider}`,
                            boxShadow: theme.vars.shadows[1]
                        },
                        [`& .${sliderClasses.rail}`]: {
                            backgroundColor: `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / 0.08)`,
                            opacity: 1,
                            height: ownerState.size === 'small' ? 6 : 8
                        },
                        [`& .${sliderClasses.track}`]: {
                            height: ownerState.size === 'small' ? 6 : 8
                        }
                    })
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        ...(ownerState.variant === 'outlined' &&
                            ownerState.color !== 'default' && {
                                color: theme.vars.palette.Chip[ownerState.color + 'OutlinedColor'],
                                backgroundColor: theme.vars.palette.background[ownerState.color],
                                borderColor: `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / 0.08)`,
                                '&.Mui-focusVisible': {
                                    borderColor: theme.vars.palette[ownerState.color]?.main
                                },
                                [`&.${chipClasses.clickable}`]: {
                                    '&:hover': {
                                        backgroundColor: `rgba(${theme.vars.palette[ownerState.color]?.mainChannel} / 0.16)`
                                    }
                                }
                            }),
                        ...(ownerState.variant === 'outlined' &&
                            ownerState.color === 'default' && {
                                backgroundColor: theme.vars.palette.action.selected,
                                borderColor: theme.vars.palette.action.selected,
                                [`&.${chipClasses.clickable}`]: {
                                    '&:hover': {
                                        backgroundColor: `rgba(${theme.vars.palette.action.selectedChannel} / 0.16)`
                                    }
                                }
                            }),
                        [`& .${chipClasses.avatar}`]: {
                            backgroundColor: theme.vars.palette[ownerState.color]?.dark
                        },
                        [`&.${chipClasses.sizeSmall} > .${chipClasses.avatar}`]: {
                            fontSize: 8
                        }
                    }),
                    avatar: ({ theme }) => ({
                        color: theme.vars.palette.text.invert
                    })
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        top: -6,
                        fontSize: 14
                    },
                    shrink: {
                        transform: 'translate(14px, -2px) scale(0.75)'
                    }
                }
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        [`&.${inputAdornmentClasses.positionStart}`]: {
                            marginRight: -2
                        }
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        fontSize: 14,
                        [`&.${inputBaseClasses.adornedStart}`]: {
                            paddingLeft: 12
                        },
                        [`&.${inputBaseClasses.adornedEnd}`]: {
                            paddingRight: 12
                        },
                        [`&.${inputBaseClasses.sizeSmall}`]: {
                            fontSize: 12,
                            [`&.${inputBaseClasses.adornedStart}`]: {
                                paddingLeft: 8
                            },
                            [`&.${inputBaseClasses.adornedEnd}`]: {
                                paddingRight: 8
                            },
                            [`& .${inputBaseClasses.inputSizeSmall}`]: {
                                padding: '5.38px 8px'
                            },
                            [`& .${selectClasses.icon}`]: {
                                fontSize: 20
                            }
                        },
                        [`& .${inputBaseClasses.input}`]: {
                            padding: '10px 12px'
                        },
                        [`&.${inputBaseClasses.multiline}`]: {
                            padding: '8.5px 12px',
                            [`& .${inputBaseClasses.inputMultiline}`]: {
                                paddingTop: 2,
                                paddingBottom: 1,
                                paddingLeft: 0,
                                paddingRight: 0
                            }
                        }
                    }
                }
            },
            MuiInput: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        '&:hover': {
                            backgroundColor: theme.vars.palette.action.hover
                        },
                        '&.Mui-focused': {
                            outline: `2px solid ${theme.vars.palette[ownerState.color]?.main}`
                        },

                        '&.Mui-error': {
                            backgroundColor: `rgba(${theme.vars.palette.error.mainChannel} / 0.08)`,
                            outlineColor: theme.vars.palette.error.main
                        },
                        '&.Mui-disabled': {
                            '&:hover': {
                                backgroundColor: 'transparent'
                            }
                        }
                    })
                }
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        backgroundColor: theme.vars.palette.FilledInput.bg,
                        border: `1px solid ${theme.vars.palette.grey[100]}`,
                        '&.Mui-focused': {
                            outline: `2px solid ${theme.vars.palette[ownerState.color]?.main}`
                        },
                        '&.Mui-error': {
                            backgroundColor: `rgba(${theme.vars.palette.error.mainChannel} / 0.08)`,
                            borderColor: `rgba(${theme.vars.palette.error.mainChannel} / 0.08)`,
                            outlineColor: theme.vars.palette.error.main
                        },
                        '&.Mui-disabled': {
                            borderColor: theme.vars.palette.FilledInput.disabledBorder
                        }
                    })
                }
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        [`&.${autocompleteClasses.root}.${autocompleteClasses.root} .${autocompleteClasses.inputRoot}`]: {
                            [`&.${inputBaseClasses.root}`]: {
                                paddingTop: 2.5,
                                paddingBottom: 2.5
                            }
                        }
                    },
                    listbox: {
                        padding: 8,
                        [`&.${autocompleteClasses.listbox} > *`]: {
                            marginTop: 2
                        },
                        [`&.${autocompleteClasses.listbox} .${listItemClasses.root}:first-child`]: {
                            marginTop: 0
                        }
                    }
                }
            },
            MuiAlert: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        paddingTop: 0,
                        paddingBottom: 0,
                        alignItems: 'center',
                        ...(ownerState.severity === 'secondary' && {
                            color: theme.vars.palette[ownerState.severity]?.dark
                        }),
                        [`& .${alertClasses.action}`]: {
                            padding: 0
                        }
                    }),
                    filled: ({ ownerState, theme }) => ({
                        color: theme.vars.palette.text.invert,
                        backgroundColor: theme.vars.palette[ownerState.severity]?.main
                    }),
                    outlined: ({ ownerState, theme }) => ({
                        backgroundColor: theme.vars.palette.background[ownerState.severity],
                        borderColor: `rgba(${theme.vars.palette[ownerState.severity]?.mainChannel} / 0.08)`
                    }),
                    standard: ({ ownerState, theme }) => ({
                        backgroundColor: theme.vars.palette.background[ownerState.severity]
                    }),
                    message: {
                        padding: '12px 0'
                    }
                }
            },
            MuiAlertTitle: {
                styleOverrides: {
                    root: {
                        fontSize: 14,
                        fontWeight: 'bold'
                    }
                }
            },
            MuiIcon: {
                styleOverrides: {
                    fontSizeInherit: () => ({
                        [`&.${iconClasses.fontSizeInherit}`]: {
                            fontSize: 'inherit'
                        }
                    }),
                    fontSizeSmall: () => ({
                        [`&.${iconClasses.fontSizeSmall}`]: {
                            fontSize: '1.25rem'
                        }
                    }),
                    fontSizeLarge: () => ({
                        [`&.${iconClasses.fontSizeLarge}`]: {
                            fontSize: '2.25rem'
                        }
                    })
                }
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        padding: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }
                }
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        borderRadius: theme.shape.borderRadius
                    }
                }
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        borderRadius: theme.shape.borderRadius
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        borderRadius: theme.shape.borderRadius
                    }
                }
            },
            MuiMobileStepper: {
                styleOverrides: {
                    dot: ({ theme }) => ({
                        margin: '0 4px',
                        backgroundColor: `rgba(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.disabledOpacity})`
                    }),
                    dotActive: ({ theme }) => ({
                        backgroundColor: theme.vars.palette.primary.main
                    })
                }
            }
        }
    };
};

// Alter default props of MUI components
const getDefaultProps = () => {
    return {
        components: {
            MuiIcon: {
                defaultProps: {
                    baseClassName: 'material-symbols-outlined'
                }
            },
            MuiButtonGroup: {
                defaultProps: {
                    disableElevation: true
                }
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true
                }
            },
            MuiInput: {
                defaultProps: {
                    disableUnderline: true
                }
            },
            MuiFilledInput: {
                defaultProps: {
                    disableUnderline: true,
                    hiddenLabel: true
                }
            },
            MuiTextField: {
                defaultProps: {
                    hiddenLabel: true
                }
            },
            MuiAccordion: {
                defaultProps: {
                    slotProps: {
                        transition: {
                            timeout: 0
                        }
                    }
                }
            },
            MuiListItem: {
                defaultProps: {
                    dense: true
                }
            },
            MuiListItemButton: {
                defaultProps: {
                    dense: true
                }
            },
            MuiMenuItem: {
                defaultProps: {
                    dense: true
                }
            },
            MuiMenuList: {
                defaultProps: {
                    dense: true
                }
            },
            MuiDialogTitle: {
                defaultProps: {
                    variant: 'h4'
                }
            }
        }
    };
};

//The theme is build in a few steps, template designer is not yet affected by v2 styling
const getTheme = () => {
    //Create object with Cape colors, fonts settings, shapes, shadows etc..
    let theme = capeTheme();
    //Overwrite components style or add variants, not for Template Designer
    theme = getStyleOverrides(theme);
    // Alters the MUI default props globally
    theme = deepmerge(theme, getDefaultProps());
    // Fill and create theme object for cssVarsProvider
    return createTheme({ cssVariables: { disableCssColorScheme: true, colorSchemeSelector: 'data-mui-color-scheme' }, ...theme });
};

export default getTheme;
