import { Brick } from 'components/bricks/types/brick.type';
import { ExpandSections } from 'components/template-designer/components/ui-components/expand-section';
import { AssetV2 } from './asset.type';

export enum EventEmitterTypes {
    // Bricks types
    BricksAddBrick = 'BricksAddBrick',
    // Creative Editor types
    CEiframe = 'CEiframeAction',
    CEcurrentTime = 'CEcurrentTime',
    CEhoverPosition = 'CEhoverPosition',
    // Template Designer types
    TDcurrentTime = 'TDcurrentTime',
    TDzoomLevel = 'TDzoomLevel',
    TDlayerDrag = 'TDlayerDrag',
    TDsecondInPx = 'TDsecondInPx',
    TDlayerEditExpandSection = 'TDlayerEditExpandSection',
    TDopenGalleryOnCompressor = 'TDopenGalleryOnCompressor',
    // Media Player types
    MPcurrentFrame = 'MPcurrentFrame',
    MPcurrentTime = 'MPcurrentTime',
    // Asset Management types
    AMV2refreshAsset = 'AMV2refreshAsset'
}

export type EventEmitterPayloads = {
    [EventEmitterTypes.BricksAddBrick]: BricksAddBrickPayload;
    [EventEmitterTypes.CEiframe]: CEiframeEventPayload;
    [EventEmitterTypes.CEcurrentTime]: CEcurrentTimeEventPayload;
    [EventEmitterTypes.CEhoverPosition]: CEhoverPositionEventPayload;
    [EventEmitterTypes.TDcurrentTime]: TDcurrentTimeEventPayload;
    [EventEmitterTypes.TDzoomLevel]: TDcurrentTimeEventPayload;
    [EventEmitterTypes.TDlayerDrag]: TDlayerDragEventPayload;
    [EventEmitterTypes.TDsecondInPx]: TDsecondInPxEventPayload;
    [EventEmitterTypes.TDlayerEditExpandSection]: TDlayerEditExpandSectionEventPayload;
    [EventEmitterTypes.TDopenGalleryOnCompressor]: TDopenGalleryOnCompressorEventPayload;
    [EventEmitterTypes.MPcurrentFrame]: MPcurrentFrameEventPayload;
    [EventEmitterTypes.MPcurrentTime]: MPcurrentTimeEventPayload;
    [EventEmitterTypes.AMV2refreshAsset]: AMV2refreshAssetEventPayload;
};

export interface CEiframeEventPayload {
    action:
        | {
              type: 'play' | 'pause';
          }
        | {
              type: 'seek';
              seekTime: number; // number between 0 and 1, as a percentage
              playAfterSeek?: boolean;
          }
        | {
              type: 'screenshot';
              format: string;
              options?: {
                  scale?: number;
              };
          }
        | {
              type: 'getLayerStyles';
              format: string;
              layerKey: string;
          };
}

export type CEcurrentTimeEventPayload = number;
export type CEhoverPositionEventPayload = number | null;
export type TDcurrentTimeEventPayload = number;
export type TDzoomLevel = number;
export type TDlayerDragEventPayload = number;
export type TDsecondInPxEventPayload = number;
export type TDlayerEditExpandSectionEventPayload = {
    action: 'open' | 'close' | 'oneOpen';
    expandSectionKey: ExpandSections | 'all';
};
export type TDopenGalleryOnCompressorEventPayload = boolean;
export type MPcurrentFrameEventPayload = number;
export type MPcurrentTimeEventPayload = number;
export type AMV2refreshAssetEventPayload = AssetV2<unknown, unknown>;
export type BricksAddBrickPayload = Brick;
